import React, {useState, useEffect} from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import { styled, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import MuiDrawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import InventoryIcon from '@mui/icons-material/Inventory'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import ImportContactsIcon from '@mui/icons-material/ImportContacts'
import { SvgIconTypeMap, SvgIconProps, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import HomeIcon from '@mui/icons-material/Home'
import AppBar from '@mui/material/AppBar'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'

import { PageHeadingTypography } from '@styles/Typography'
import Pages from '@pages/Page'

import { SVGCertificateIcon, SVGShirtIcon } from './icons'
import { transformStringToKebabCase } from '@utils/utils/util'
import { getLocalStorageCompany } from '@utils/localStorage/company'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
)


interface ListComponent {
    open: boolean
    name: string
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string
    } | React.FC<SvgIconProps>
}

const ReportingListComponent = (props: ListComponent) => {
    const {open, name, Icon} = props

    const navigate = useNavigate()

    const [is_tooltip_open, setIsTooltipOpen] = useState(false)

    const handleTooltipOpen = () => {
        if (!open)
            setIsTooltipOpen(true)
    }

    const handleTooltipClose = () => {
        setIsTooltipOpen(false)
    }

    const handleClick = (kebab_case_name: string) => {
        navigate(`/reporting/${kebab_case_name}`)
    }

    return (
        <Tooltip 
            title={name}
            open={is_tooltip_open}
            onOpen={handleTooltipOpen}
            onClose={handleTooltipClose}
            enterDelay={1000}
            placement='right'
            slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -20],
                      },
                    },
                  ],
                },
            }}
        >
            <ListItem key={name} disablePadding sx={{ display: 'block', width: '50px' }}>
                <ListItemButton
                    sx={{
                        // minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                    onClick={() => handleClick(transformStringToKebabCase(name))}
                >
                    <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                    }}
                    >
                        <Icon/>
                    </ListItemIcon>
                    <ListItemText primary={name} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
        </Tooltip>
    )
}


interface ReportingPageProps {
    children: React.ReactNode
}

interface ReportingItem {
    name: string
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string
    } | React.FC<SvgIconProps>
}


const ReportingPage = (props: ReportingPageProps) => {
    const {children} = props

    const location = useLocation()

    const [open, setOpen] = useState(false)
    const [is_screen_small, setIsScreenSmall] = useState(false)

    const connected_company = getLocalStorageCompany()
	const acts_like_a_franchise = connected_company.acts_like_a_franchise

    let reporting_part = location.pathname.split('/reporting/')[1]
    reporting_part = reporting_part ? reporting_part.split('/')[0] : ''

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 550px)')
    
        // Define the event listener callback function
        const handleMediaQueryChange = (event: MediaQueryListEvent) => {
            setIsScreenSmall(event.matches)
        }
    
        // Add the event listener to listen for changes
        mediaQuery.addEventListener('change', handleMediaQueryChange)
    
        // Check the initial state
        if (mediaQuery.matches) {
          setIsScreenSmall(true)
        }
    
        // Cleanup function to remove the event listener
        return () => {
          mediaQuery.removeEventListener('change', handleMediaQueryChange)
        }
    }, [])

    const reporting_items: ReportingItem[] = [
        { name: 'Dashboard', Icon: HomeIcon},
        { name: 'T-Shirts', Icon: SVGShirtIcon },
        { name: 'Certificates', Icon: SVGCertificateIcon },
    ]
    
    if (acts_like_a_franchise) {
        reporting_items.push(
            { name: 'Class Registers', Icon: HowToRegIcon },
            { name: 'Assessments', Icon: FactCheckIcon },
            { name: 'Weekly schedule', Icon: CalendarMonthIcon }
        )
    }
    else {
        reporting_items.push(
            { name: 'Franchise Details', Icon: ImportContactsIcon },
            { name: 'Franchise Sales', Icon: MonetizationOnIcon },
            { name: 'Package slip', Icon: InventoryIcon },
            { name: 'Member Authorisation', Icon: VerifiedUserIcon },
        )
    }

    // if (process.env.REACT_APP_ENVIRONMENT === 'development' || companyIsInGroup(connected_company, CompanyType.MASTER_FRANCHISE)) {
    //     reporting_items.push(
    //         { name: 'Handouts', Icon: SVGBasketIcon },
    //     )
    // }

    let use_reporting_item: ReportingItem | undefined = reporting_items.find(reporting_item => transformStringToKebabCase(reporting_item.name) === reporting_part)
    if (!use_reporting_item)
        use_reporting_item = reporting_items[0]

    const handleDrawerOpenClose = () => {
        setOpen(!open)
    }

    const list = (
        <List sx={is_screen_small ? {display: 'flex', alignItems: 'center', gap: '1rem', width: '85%', justifyContent: 'space-around', height: '60p', margin: 'auto'} : {}}>
            {reporting_items.map(item => (
                <ReportingListComponent
                    key={item.name}
                    open={open}
                    name={item.name}
                    Icon={item.Icon}
                />
            ))}
        </List>
    )

    return (
        <Pages page_title="Franchisees">
            <Box display={'flex'} sx={is_screen_small ? {flexDirection: 'column' } : {}}>
                <CssBaseline />
                {is_screen_small ?
                    <AppBar position="static">
                        <Toolbar sx={{width: '100%', padding: 0, background: 'white', overflowX: 'scroll'}}>
                            {list}
                        </Toolbar>
                    </AppBar>
                    : <></>
                }
                {!is_screen_small ?
                    <Drawer 
                        variant="permanent" 
                        open={open} 
                        sx={{zIndex: 50}}
                    >
                        <Toolbar/>
                        <DrawerHeader>
                        <IconButton onClick={handleDrawerOpenClose}>
                            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                        </DrawerHeader>
                        <Divider />
                        {list}
                        <Divider />
                    </Drawer>
                    : <></>
                }
                <Box component="main" sx={{ flexGrow: 1, p: 3, width: is_screen_small ? '100%' : 'calc(100vw - 300px)' }}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: '2rem'}}>
                        <PageHeadingTypography sx={{marginBottom: 0}}> Reporting </PageHeadingTypography>
                        <Typography sx={{alignItems: 'center', display: 'flex', gap: '0.3rem', mt: '0.35rem'}}>
                            <use_reporting_item.Icon fontSize="small"/>
                            {use_reporting_item.name}
                        </Typography>
                    </Breadcrumbs>
                    {children}
                </Box>
            </Box>
        </Pages>
    )
}

export default ReportingPage