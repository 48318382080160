import { useTranslation } from 'react-i18next'

import { CRUDAction } from '@actions/Actions/actions'
import ChildForm from '@components/forms/ChildForm'
import Table from '@components/tables/Table/Table'
import UnarchiveChildDialogHandler from '@components/dialogs/UnarchiveChildDialog'
import '../styles.css'


export interface TableProps {
	action: CRUDAction
}

const gender_map: Record<number, string>  = {
	1: 'M',
	2: 'F', 
	3: 'Other'
}

const shirt_map: Record<number, string>  = {
	1: 'Extra small',
	2: 'Small',
	3: 'Medium', 
	4: 'Large',
	5: 'Extra large'
}

const valueGetter = (params: any) => {
	if (params.field === 'sex')
		return gender_map[params.value] || ''

	else if (params.field === 'shirt_size')
		return shirt_map[params.value] || ''
}

const ArchivedChildTable = (props: TableProps) => {
	const {action} = props
    const { t } = useTranslation('children')

	const columns = [
		{ field: 'id', headerName: t('id') },
		{ field: 'username', headerName: t('name'), minWidth: 200 },
		{ field: 'age', sortable: false, filterable: false, headerName: t('age') },
		{ field: 'parent_email', sortable: false, headerName: t('parents_email'), minWidth: 200 },
		{ field: 'parent_phone', sortable: false, headerName: t('phone_no'), minWidth: 200 },
		{ field: 'sex', sortable: false, filterable: false, headerName: t('gender'), cellClassName: 'center-aligned-cell', maxWidth: 85, valueGetter },
		{ field: 'registered', sortable: false, filterable: false, headerName: t('registered') },
		{ field: 'birthday', sortable: false, filterable: false, headerName: t('birthday') },
	]

	const paginationFilterParser = (field: string, value: string) => {
		let custom_operator = undefined

		if (field === 'parent_email') {
			field = 'preferred_parent_contact__email'
		}
		else if (field === 'parent_phone') {
			field = 'preferred_parent_contact__phone_number'
		}
		return {field, value, custom_operator}
	}

	const paginationSortParser = (field: string) => {
		return field
	}

	

    return (
        <Table
		dialogs={[
			UnarchiveChildDialogHandler, 
		  ]}
			action={action} 
			Form={ChildForm} 
			columns={columns} 
			use_pagination={true}
			filter={{archive: true, is_old: false}}
			page_size={100}
			paginationFilterParser={paginationFilterParser}
			paginationSortParser={paginationSortParser}
			amount_of_rows_selection={[100]}
		/>
    )
}

export default ArchivedChildTable