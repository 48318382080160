import { useTranslation } from 'react-i18next'

import Table from '@components/tables/Table/Table'
import ChildPaymentsActions from '@actions/Finance/PaymentActions/childPaymentActions'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Cancel from '@mui/icons-material/Cancel'
import ChildMonthlyAuthorisationHandler from './childMonthlyAuthorisationHandler'
import { paymentsTableValueGetter } from '../ChildYearlyTable'
import { GridColumnVisibilityModel } from '@mui/x-data-grid'
import { companyIsInGroup } from '@utils/utils/util'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { CompanyType } from '@utils/enums/enums'

export interface TableProps {
	action: ChildPaymentsActions
}

const ChildMonthlyTable = (props: TableProps) => {
    const {action} = props
    const { t } = useTranslation('payments')

    const company = getLocalStorageCompany()

    const columns = [
        { field: 'id', headerName: t('id') },
        { field: 'child_name', headerName: t('name'), minWidth: 250, flex: 0.2 },
        { field: 'school_name', headerName: t('school'), minWidth: 250, flex: 0.2 },
        { field: 'child_start_date', sortable: false, filterable: false, headerName: t('start_date'), minWidth: 120, flex: 0.2, valueGetter: paymentsTableValueGetter},
        { field: 'jan', headerName: t('jan'), cellClassName: 'center-aligned-cell', width: 75, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
				else
					return <Cancel sx={{ color: '#ed586e' }} />
            },
        },
        { field: 'feb', headerName: t('feb'), cellClassName: 'center-aligned-cell', width: 75, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
				else
					return <Cancel sx={{ color: '#ed586e' }} />
            },
        },
        { field: 'mar', headerName: t('mar'), cellClassName: 'center-aligned-cell', width: 75, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
				else
					return <Cancel sx={{ color: '#ed586e' }} />
            },
        },
        { field: 'apr', headerName: t('apr'), cellClassName: 'center-aligned-cell', width: 75, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
				else
					return <Cancel sx={{ color: '#ed586e' }} />
            },
        },
        { field: 'may', headerName: t('may'), cellClassName: 'center-aligned-cell', width: 75, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
				else
					return <Cancel sx={{ color: '#ed586e' }} />
            },
        },
        { field: 'jun', headerName: t('jun'), cellClassName: 'center-aligned-cell', width: 75, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
				else
					return <Cancel sx={{ color: '#ed586e' }} />
            },
        },
        { field: 'jul', headerName: t('jul'), cellClassName: 'center-aligned-cell', width: 75, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
				else
					return <Cancel sx={{ color: '#ed586e' }} />
            },
        },
        { field: 'aug', headerName: t('aug'), cellClassName: 'center-aligned-cell', width: 75, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
				else
					return <Cancel sx={{ color: '#ed586e' }} />
            },
        },
        { field: 'sep', headerName: t('sep'), cellClassName: 'center-aligned-cell', width: 75, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
				else
					return <Cancel sx={{ color: '#ed586e' }} />
            },
        },
        { field: 'oct', headerName: t('oct'), cellClassName: 'center-aligned-cell', width: 75, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
				else
					return <Cancel sx={{ color: '#ed586e' }} />
            },
        },
        { field: 'nov', headerName: t('nov'), cellClassName: 'center-aligned-cell', width: 75, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
				else
					return <Cancel sx={{ color: '#ed586e' }} />
            },
        },
        { field: 'dec', headerName: t('dec'), cellClassName: 'center-aligned-cell', width: 75, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
				else
					return <Cancel sx={{ color: '#ed586e' }} />
            },
        },
    ]

    const column_visibility_model: GridColumnVisibilityModel = {}

    if (companyIsInGroup(company, CompanyType.FRANCHISEE))
        column_visibility_model['jan'] = false
        column_visibility_model['dec'] = false

    return (
        <Table
            dialogs={[ChildMonthlyAuthorisationHandler]}
            action={action}column_visibility_model={column_visibility_model}
            filter={{'payment_structure': 'monthly'}}
            columns={columns}
            page_size={100}
            amount_of_rows_selection={[100]}
            sort_by_asc='child_name'
        />
    )
}

export default ChildMonthlyTable