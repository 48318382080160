import { useTranslation } from 'react-i18next'

import Pages from '@pages/Page'
import { PageHeadingTypography } from '@styles/Typography'
import { UserPermissions } from '@utils/enums/permissions'
import SchoolsTabs from './SchoolsTabs'


const SchoolsPage = () => {

	const { t } = useTranslation('school_form')

	return (
		<Pages page_title="Schools" permission={UserPermissions.VIEW_SCHOOLS}>
			<PageHeadingTypography> {t('schools')} </PageHeadingTypography>
            <SchoolsTabs/>
		</Pages>
	)
}

export default SchoolsPage