import BillsActions from '@actions/Finance/BillsActions/billsActions'
import BillsTable from '@components/tables/Custom/Finance/BillsTable'

import FinancesPage from '../Finances'


const bills_actions = new BillsActions()

const BillsPage = () => {

	return (
		<FinancesPage>
			{/* <AddXeroContactDialogHandler/> */}
			<br/>
			<BillsTable action={bills_actions}/>
		</FinancesPage>
	)
}

export default BillsPage
